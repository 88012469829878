<template>
  <transition :appear="true" name="slide">
    <div @touchmove.prevent class="desc">
      <scroll ref="scroll" class="scroll" :data="data">
        <div>
          <div class="title">
            内蒙古图书馆码上悦读平台
            <br>
            使用条款和隐私政策
          </div>
          <div class="content-block">
            <div class="content">
              欢迎您使用 内图码上悦读（以下简称 码上悦读）应用及相关服务！
            </div>
            <div class="content">
              为了更好地为您提供服务，请您仔细阅读《内图码上悦读使用条款和隐私政策》（以下简称“本协议”）。在您开始使用
              内图码上悦读软件及相关服务之前，请您务必认真阅读并充分理解本协议，特别是涉及免除或者限制责任的条款、权利许可和信息使用的条款、同意开通和使用特殊单项服务的条款、法律适用和争议解决条款等。如您未满18周岁，请您在法定监护人陪同下仔细阅读并充分理解本协议，并征得法定监护人的同意后下载本软件。
            </div>
            <div class="content">
              若您使用 内图码上悦读软件及相关服务，则视为您已充分理解本协议并承诺作为本协议的一方当事人接受协议的约束。
            </div>
            <div class="content">
              1、适用范围
            </div>
            <div class="content">
              1.1 本协议是就您下载、安装、注册、登录、使用 内图码上悦读软件，并获得
              内图码上悦读软件提供的相关服务所订立的协议。就本协议项下涉及的某些服务，您知晓并同意接受上述服务内容，即视为接受双方之间的相关权利义务关系亦受本协议约束。
            </div>
            <div class="content">
              1.2 “用户”指所有直接或间接获取和使用 内图码上悦读软件及相关服务的使用者，包括自然人、法人和其他组织等。在本协议中称为“用户”或称“您”。
            </div>
            <div class="content">
              1.3 内图码上悦读指由码上悦读合法拥有并运营的、标注名称为：内图码上悦读的微信端应用程序，码上悦读向用户提供图书馆线上数字资源服务等相关功能。
            </div>
            <div class="content">
              1.4 本协议内容同时包括码上悦读已经发布及后续可能不断发布的关于
              内图码上悦读软件及相关服务的相关协议、规则等内容。前述内容一经正式发布，并以适当的方式送达用户（网站公布、系统通知等），即为本协议不可分割的组成部分，您应同样遵守。
            </div>
            <div class="content">
              2、使用 内图码上悦读软件及相关服务
            </div>
            <div class="content">
              2.1 您使用 内图码上悦读软件及相关服务，可以通过预装、码上悦读已授权的第三方下载等方式获取 内图码上悦读客户端应用程序或访问
              内图码上悦读相关网站。若您并非从码上悦读或经码上悦读授权的第三方获取本软件的，码上悦读无法保证非官方版本的 内图码上悦读软件能够正常使用，您因此遭受的损失与码上悦读无关。
            </div>
            <div class="content">
              2.2 码上悦读可能为不同的终端设备开发了不同的应用程序软件版本，您应当根据实际设备状况获取、下载、安装合适的版本。
            </div>
            <div class="content">
              2.3 您可根据自行需要使用 内图码上悦读软件及相关服务或更新 内图码上悦读版本，如您不再需要使用 内图码上悦读软件及相关服务可自行卸载。
            </div>
            <div class="content">
              2.4 为更好的提升用户体验及服务，码上悦读将不定期提供软件更新或改变（包括但不限于软件修改、升级、功能强化、开发新服务、软件替换等）。为保证
              内图码上悦读软件及相关服务安全、提升用户服务，本软件及相关服务更新或部分服务内容更新后，在可能的情况下，码上悦读将以包括但不限于系统提示、公告、站内信等方式提示用户，用户有权选择接受更新版本或服务，如用户不接受，部分功能将受到限制或不能继续使用。
            </div>
            <div class="content">
              2.5 除非得到码上悦读事先书面授权，您不得以任何形式对 内图码上悦读软件及相关服务进行包括但不限于改编、复制、传播、垂直搜索、镜像或交易等未经授权的访问或使用。
            </div>
            <div class="content">
              2.6 您理解，您使用 内图码上悦读软件及相关服务需自行准备与软件及相关服务有关的终端设备（如电脑、手机等），一旦您在其终端设备中打开 内图码上悦读软件，即视为您使用 内图码上悦读软件及相关服务。为充分实现
              内图码上悦读的全部功能，您可能需要将其终端设备联网，您理解由您承担所需要的费用（如流量费、上网费等）。
            </div>
            <div class="content">
              2.7 码上悦读许可您个人的、不可转让的、非独占地和非商业的合法使用
              内图码上悦读软件及相关服务的权利。本协议未明示授权的其他一切权利仍由码上悦读保留，您在行使该些权利时须另行获得码上悦读的书面许可，同时码上悦读如未行使前述任何权利，并不构成对该权利的放弃。
            </div>
            <div class="content">
              2.8 为使您更好地使用 内图码上悦读软件及相关服务，保障您的账号安全，某些功能和/或某些单项服务项目，要求您按照国家相关法律法规的规定，提供真实的身份信息实名注册并登陆后方可使用。
            </div>
            <div class="content">
              3、关于“账号”
            </div>
            <div class="content">
              3.1 内图码上悦读为用户提供了注册通道，用户有权选择合法的字符组合作为自己的账号，并自行设置符合安全要求的密码。用户设置的账号、密码是用户用以登录并以注册用户身份使用 内图码上悦读软件及相关服务的凭证。
            </div>
            <div class="content">
              3.2
              您理解并承诺，您所设置的账号不得违反国家法律法规及码上悦读的相关规则，您的账号名称、头像和简介等注册信息及其他个人信息中不得出现违法和不良信息，未经他人许可不得用他人名义（包括但不限于冒用他人姓名、名称、字号、头像等足以让人引起混淆的方式）开设账号，不得恶意注册
              内图码上悦读账号（包括但不限于频繁注册、批量注册账号等行为）。您在账号注册及使用过程中需遵守相关法律法规，不得实施任何侵害国家利益、损害其他公民合法权益，有害社会道德风尚的行为。码上悦读有权对您提交的注册信息进行审核。
            </div>
            <div class="content">
              3.3 您在
              内图码上悦读中的注册账号所有权及有关权益均归码上悦读所有，您完成注册手续后仅享有该账号的使用权。您的账号仅限于您本人使用，未经码上悦读书面同意，禁止以任何形式赠与、借用、出租、转让、售卖或以其他方式许可他人使用该账号。如果码上悦读发现或者有合理理由认为使用者并非账号初始注册人，码上悦读有权在未通知您的情况下，暂停或终止向该注册账号提供服务，并有权注销该账号，而无需向注册该账号的用户承担法律责任。
            </div>
            <div class="content">
              3.4 您有责任维护个人账号、密码的安全性与保密性，并对您以注册账号名义所从事的活动承担全部法律责任，包括但不限于您在
              内图码上悦读上进行的任何数据修改、言论发表、款项支付等操作行为。您应高度重视对账号与密码的保密，在任何情况下不向他人透露账号及密码。若发现他人未经许可使用您的账号或发生其他任何安全漏洞问题时，您应当立即通知码上悦读。
            </div>
            <div class="content">
              3.5
              您的账号在丢失或遗忘密码后，可遵照码上悦读的申诉途径及时申诉请求找回账号或密码。您理解并认可，码上悦读的密码找回机制仅需要识别申诉单上所填资料与系统记录资料具有一致性，而无法识别申诉人是否系真正账号有权使用者。码上悦读特别提醒您应妥善保管您的账号和密码。当您使用完毕后，应安全退出。因您保管不当可能导致遭受盗号或密码丢失，责任由您自行承担。
            </div>
            <div class="content">
              3.6
              在注册、使用和管理账号时，您应保证注册账号时填写的身份信息的真实性，请您在注册、管理账号时使用真实、准确、合法、有效的相关身份证明材料及必要信息（包括您的姓名及电子邮件地址、联系电话、联系地址等）。依照国家相关法律法规的规定，为使用本软件及服务，您需要填写真实的身份信息，请您按照相关法律规定完成实名认证，并注意及时更新上述相关信息。若您提交的材料或提供的信息不准确、不真实、不合法或者码上悦读有理由怀疑为错误、不实或不合法的资料，则码上悦读有权拒绝为您提供相关服务或您可能无法使用
              内图码上悦读软件及相关服务或在使用过程中部分功能受到限制。
            </div>
            <div class="content">
              3.7 除自行注册 内图码上悦读账号外，用户也可授权使用其合法拥有的包括但不限于码上悦读其他软件用户账号，以及实名注册的第三方软件或平台用户账号注册并登录使用
              内图码上悦读软件及相关服务，但第三方软件或平台对此有限制或禁止的除外。当用户以前述已有账号登录使用的，应保证相应账号已进行实名注册登记，同样适用本协议中的相关约定。
            </div>
            <div class="content">
              3.8 您理解并同意，除您登录、使用 内图码上悦读软件及相关服务外，您还可以用 内图码上悦读账号登录使用码上悦读及其关联码上悦读、控制码上悦读提供的其他软件、服务。您以
              内图码上悦读账号登陆并使用前述服务的，同样应受实际服务提供方的《用户协议》及其他协议条款约束。
            </div>
            <div class="content">
              3.9 因您自身原因或其他不可抗因素而导致账号被盗、丢失，均由您本人承担责任，码上悦读不承担任何责任。
            </div>
            <div class="content">
              4、用户个人信息保护
            </div>
            <div class="content">
              4.1 码上悦读与用户一同致力于用户个人信息（即能够独立或与其他信息结合后识别用户身份的信息）的保护，保护用户个人信息是码上悦读的基本原则之一。
            </div>
            <div class="content">
              4.2 码上悦读将运用与 内图码上悦读软件及相关服务相匹配的安全技术及其他安全措施并建立完善的管理制度来保护您的个人信息。
            </div>
            <div class="content">
              4.3 您可随时浏览、修改自己提交的个人身份信息，您理解并同意出于安全性和身份识别（如账号或密码找回申诉服务等）的考虑，您可能无法修改注册时提供的初始注册信息及其他验证信息。
            </div>
            <div class="content">
              4.4 未经您本人允许，我们不会向任何第三方共享、转让、公开披露您的个人信息，下列情形除外：
            </div>
            <div class="content">
              （1）事先获得您的明确授权同意；
            </div>
            <div class="content">
              （2）您自行向第三方共享、转让、公开的；
            </div>
            <div class="content">
              （3）与国家安全、国防安全、公共安全、公共卫生、公共利益直接相关的；
            </div>
            <div class="content">
              （4）根据适用的法律法规、法规程序的要求、强制性的行政司法要求所必须的情况下进行披露或提供，或与犯罪侦查、起诉、审判和判决执行等直接相关的；我们会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。我们将对所有的请求都进行了审慎的审查，以确保其具备合法依据，且仅限于行政、司法部门因特定调查目的有合法权利获取的数据；
            </div>
            <div class="content">
              （5） 在法律法规允许的范围内，为维护
              内图码上悦读其他用户、码上悦读及其关联码上悦读、控制码上悦读的生命、财产等合法权益或维权产品或服务的安全稳定运行所必需的，例如查找、预防、处理欺诈等违法活动和减少信用风险等；不过这并不包括违反本隐私政策中所做的承诺而为获利目的对外公开或提供个人信息；
            </div>
            <div class="content">
              （6） 码上悦读为维护合法权益而向用户提起诉讼或仲裁；
            </div>
            <div class="content">
              （7） 在涉及合并、分立、收购、资产转让或类似的交易时，如涉及到个人信息转让，码上悦读会要求新的持有您的个人信息的码上悦读、组织继续受本隐私政策的约束，否则，码上悦读有权要求该码上悦读、组织重新取得您的授权同意；
            </div>
            <div class="content">
              （8）从合法公开披露的信息中个人信息的，如合法的新闻报道、政府信息公开等渠道；
            </div>
            <div class="content">
              （9）为学术研究目的，或为学研究机构，出于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
            </div>
            <div class="content">
              （10）法律法规规定的其他情形。
            </div>
            <div class="content">
              4.5 请您注意勿在使用 内图码上悦读软件及相关服务中透露自己的各类财产账户、银行卡、信用卡、第三方支付账户及对应密码等重要资料，否则由此带来的任何损失由您自行承担。您不应将个人信息通过
              内图码上悦读上发表、上传或扩散。
            </div>
            <div class="content">
              5、用户行为规范
            </div>
            <div class="content">
              5.1 用户行为要求
            </div>
            <div class="content">
              您应当对您使用本产品及相关服务的行为负责，除非法律允许或者经码上悦读事先书面许可，您使用 内图码上悦读软件及相关服务不得具有下列行为：
            </div>
            <div class="content">
              5.1.1 使用未经码上悦读授权或许可的任何插件、外挂、系统或第三方工具对 内图码上悦读软件及相关服务的正常运行进行干扰、破坏、修改或施加其他影响。
            </div>
            <div class="content">
              5.1.2 利用或针对 内图码上悦读软件及相关服务进行任何危害计算机网络安全的行为，包括但不限于：
            </div>
            <div class="content">
              （1）非法侵入他人网络、干扰他人网络正常功能、窃取网络数据等危害网络安全的活动；
            </div>
            <div class="content">
              （2）提供专门用于从事侵入网络、干扰网络正常功能及防护措施、窃取网络数据等危害网络安全活动的程序、工具；
            </div>
            <div class="content">
              （3）明知他人从事危害网络安全的活动的，为其提供技术支持、广告推广、支付结算等帮助
            </div>
            <div class="content">
              （4）使用未经许可的数据或进入未经许可的服务器/账号；
            </div>
            <div class="content">
              （5）未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；
            </div>
            <div class="content">
              （6）未经许可，企图探查、扫描、测试 内图码上悦读系统或网络的弱点或其它实施破坏网络安全的行为；
            </div>
            <div class="content">
              （7）企图干涉、破坏 内图码上悦读系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；
            </div>
            <div class="content">
              （8）伪造TCP/IP数据包名称或部分名称。
            </div>
            <div class="content">
              5.1.3 对 内图码上悦读软件进行反向工程、反向汇编、编译或者以其他方式尝试发现本软件的源代码。
            </div>
            <div class="content">
              5.1.4 恶意注册 内图码上悦读账号，包括但不限于频繁、批量注册账号。
            </div>
            <div class="content">
              5.1.5
              违反法律法规、本协议、码上悦读的相关规则及侵犯他人合法权益的其他行为。在任何情况下，如果码上悦读有理由认为您的任何行为违反或可能违反上述约定的，码上悦读可独立进行判断并处理，且在任何时候有权在进行任何事先通知的情况下终止向用户提供服务，并追究相关责任。
            </div>
            <div class="content">
              5.2 信息内容规范
            </div>
            <div class="content">
              5.2.1 用户按规定完成实名认证后，可以以注册账号或 内图码上悦读合作平台账号登录 内图码上悦读发布内容、跟帖评论等。
            </div>
            <div class="content">
              5.2.2
              码上悦读致力使跟帖评论成为文明、理性、友善、高质量的意见交流。在推动跟帖评论业务发展的同时，不断加强相应的信息安全管理能力，完善跟帖评论自律，切实履行社会责任，遵守国家法律法规，尊重公民合法权益，尊重社会公序良俗。
            </div>
            <div class="content">
              5.2.3
              用户评论、发布、传播的内容应自觉遵守宪法法律、法规、遵守公共秩序，尊重社会公德、社会主义制度、国家利益、公民合法权益、道德风尚和信息真实性等要求。用户不得制作、复制、发布、传播法律、行政法规禁止的下列信息内容：
            </div>
            <div class="content">
              （1）反对宪法确定的基本原则的；
            </div>
            <div class="content">
              （2）危害国家安全，泄露国家秘密的；
            </div>
            <div class="content">
              （3）颠覆国家政权，推翻社会主义制度、煽动分裂国家、破坏国家统一的；
            </div>
            <div class="content">
              （4）损害国家荣誉和利益的；
            </div>
            <div class="content">
              （5）宣扬恐怖主义、极端主义的；
            </div>
            <div class="content">
              （6）宣扬民族仇恨、民族歧视，破坏民族团结的；
            </div>
            <div class="content">
              （7）煽动地域歧视、地域仇恨的；
            </div>
            <div class="content">
              （8）破坏国家宗教政策，宣扬邪教和迷信的；
            </div>
            <div class="content">
              （9）编造、散布谣言、虚假信息，扰乱经济秩序和社会秩序、破坏社会稳定的；
            </div>
            <div class="content">
              （10）散布、传播暴力、淫秽、色情、赌博、凶杀、恐怖或者教唆犯罪的；
            </div>
            <div class="content">
              （11）侵害未成年人合法权益或者损害未成年人身心健康的；
            </div>
            <div class="content">
              （12）未获他人允许，偷拍、偷录他人，侵害他人合法权利的；
            </div>
            <div class="content">
              （13）包含恐怖、暴力血腥、高危险性、危害表演者自身或他人身心健康内容的；
            </div>
            <div class="content">
              （14）危害网络安全、利用网络从事危害国家安全、荣誉和利益的；
            </div>
            <div class="content">
              （15）侮辱或者诽谤他人，侵害他人合法权益的；
            </div>
            <div class="content">
              （16）对他人进行暴力恐吓、威胁，实施人肉搜索的；
            </div>
            <div class="content">
              （17）涉及他人隐私、个人信息或资料的；
            </div>
            <div class="content">
              （18）散布污言秽语，损害社会公序良俗的；
            </div>
            <div class="content">
              （19）侵犯他人隐私权、名誉权、肖像权、知识产权等合法权益内容的；
            </div>
            <div class="content">
              （20）散布商业广告，或类似的商业招揽信息、过度营销信息及垃圾信息；
            </div>
            <div class="content">
              （21）使用本网站常用语言文字以外的其他语言文字评论的；
            </div>
            <div class="content">
              （22）与所评论的信息毫无关系的；
            </div>
            <div class="content">
              （23）所发表的信息毫无意义的，或刻意使用字符组合以逃避技术审核的；
            </div>
            <div class="content">
              （24）其他违反法律法规、政策及公序良俗、干扰 内图码上悦读正常运营或侵犯其他用户或第三方合法权益内容的其他信息。
            </div>
            <div class="content">
              5.3
              码上悦读设立公众投诉、举报平台，用户可按照码上悦读公示的投诉举报制度向码上悦读投诉、举报各类违法违规行为、违法传播活动、违法有害信息等内容，码上悦读将及时受理和处理用户投诉举报，以共同营造风清气正的网络空间。
            </div>
            <div class="content">
              6、 内图码上悦读数据使用规范
            </div>
            <div class="content">
              6.1 未经码上悦读书面许可，用户不得自行或授权、允许、协助任何第三人对本协议 内图码上悦读软件及相关服务中信息内容进行如下行为：
            </div>
            <div class="content">
              （1）复制、读取、采用 内图码上悦读软件及相关服务的信息内容，用于包括但不限于宣传、增加阅读量、浏览量等商业用途；
            </div>
            <div class="content">
              （2）擅自编辑、整理、编排 内图码上悦读软件及相关服务的信息内容后在 内图码上悦读软件及相关服务的源页面以外的渠道进行展示；
            </div>
            <div class="content">
              （3）采用包括但不限于特殊标识、特殊代码等任何形式的识别方法，自行或协助第三人对 内图码上悦读软件及相关服务的的信息或内容产生流量、阅读量引导、转移、劫持等不利影响；
            </div>
            <div class="content">
              （4）其他非法获取 内图码上悦读软件及相关服务的信息内容的行为。
            </div>
            <div class="content">
              6.2 经码上悦读书面许可后，用户对 内图码上悦读软件及相关服务的信息和内容的分享、转发等行为，还应符合以下规范：
            </div>
            <div class="content">
              （1）对抓取、统计、获得的相关搜索热词、命中率、分类、搜索量、点击率、阅读量等相关数据，未经码上悦读事先书面同意，不得将上述数据以任何方式公示、提供、泄露给任何第三人；
            </div>
            <div class="content">
              （2）不得对 内图码上悦读软件及相关服务的源网页进行任何形式的任何改动，包括但不限于 内图码上悦读软件及相关服务的首页（profile页面）链接，广告系统链接等入口，也不得对
              内图码上悦读软件及相关服务的源页面的展示进行任何形式的遮挡、插入、弹窗等妨碍；
            </div>
            <div class="content">
              （3）应当采取安全、有效、严密的措施，防止 内图码上悦读软件及相关服务的信息内容被第三方通过包括但不限于“蜘蛛”（spider）程序等任何形式进行非法获取；
            </div>
            <div class="content">
              （4）不得把相关数据内容用于码上悦读书面许可范围之外的目的，进行任何形式的销售和商业使用，或向第三方泄露、提供或允许第三方为任何方式的使用。
            </div>
            <div class="content">
              （5）用户向任何第三人分享、转发、复制 内图码上悦读软件及相关服务信息内容的行为，还应遵守码上悦读为此制定的其他规范和标准。
            </div>
            <div class="content">
              （3）采用包括但不限于特殊标识、特殊代码等任何形式的识别方法，自行或协助第三人对 内图码上悦读软件及相关服务的的信息或内容产生流量、阅读量引导、转移、劫持等不利影响；
            </div>
            <div class="content">
              （3）采用包括但不限于特殊标识、特殊代码等任何形式的识别方法，自行或协助第三人对 内图码上悦读软件及相关服务的的信息或内容产生流量、阅读量引导、转移、劫持等不利影响；
            </div>
            <div class="content">
              7、违约处理
            </div>
            <div class="content">
              7.1
              针对您违反本协议或其他服务条款的行为，码上悦读有权独立判断并视情况采取预先警示、拒绝发布、立即停止传输信息、删除跟帖、短期禁止发言、限制账号部分或者全部功能直至永久关闭账号等措施。码上悦读有权公告处理结果，且有权根据实际情况决定是否恢复使用。对涉嫌违反法律法规、涉嫌违法犯罪的行为将保存有关记录，并依法向有关主管部门报告、配合有关主管部门调查。
            </div>
            <div class="content">
              7.2因您违反本协议或其他服务条款规定，引起第三方投诉或诉讼索赔的，您应当自行承担全部法律责任。因您的违法或违约行为导致码上悦读及其关联码上悦读、控制码上悦读向任何第三方赔偿或遭受国家机关处罚的，您还应足额赔偿码上悦读及其关联码上悦读、控制码上悦读因此遭受的全部损失。
            </div>
            <div class="content">
              8、服务的变更、中断和终止
            </div>
            <div class="content">
              8.1 您理解并同意，码上悦读提供的
              内图码上悦读软件及相关服务是按照现有技术和条件所能达到的现状提供的。码上悦读会尽最大努力向您提供服务，确保服务的连贯性和安全性。您理解，码上悦读不能随时预见和防范技术以及其他风险，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵及其他各种安全问题的侵扰等原因可能导致的服务中断、数据丢失以及其他的损失和风险。
            </div>
            <div class="content">
              8.2 您理解并同意，码上悦读为了服务整体运营的需要，有权在公告通知后修改、中断、中止或终止 内图码上悦读软件及相关服务，而无须向用户负责或承担任何赔偿责任。
            </div>
            <div class="content">
              9、广告
            </div>
            <div class="content">
              9.1 您使用 内图码上悦读软件及相关服务过程中，充分理解并同意：本服务中可能包括码上悦读针对个人或企业推出的信息、广告发布或品牌推广服务，您同意码上悦读有权在 内图码上悦读软件及相关服务中展示
              内图码上悦读软件及相关服务相关和/或第三方供应商、合作伙伴的商业广告、推广或信息（包括商业或非商业信息）。
            </div>
            <div class="content">
              9.2 如您不同意该广告，您有权选择关闭该广告信息； 内图码上悦读推送通知服务的，您有权自行关闭该服务或可停止使用 内图码上悦读软件及相关服务。
            </div>
            <div class="content">
              9.3
              码上悦读依照法律规定履行广告及推广相关义务，您应当自行判断该广告或推广信息的真实性和可靠性并为自己的判断行为负责。除法律法规明确规定外，您因该广告或推广信息进行的购买、交易或因前述内容遭受的损害或损失，用户应自行承担，码上悦读不予承担责任。
            </div>
            <div class="content">
              10、知识产权
            </div>
            <div class="content">
              10.1 码上悦读在
              内图码上悦读软件及相关服务中提供的内容（包括但不限于软件、技术、程序、网页、文字、图片、图像、音频、视频、图表、版面设计、电子文档等）的知识产权属于码上悦读所有。码上悦读提供本服务时所依托的软件的著作权、专利权及其他知识产权均归码上悦读所有。未经码上悦读许可，任何人不得擅自使用（包括但不限于通过任何机器人、蜘蛛等程序或设备监视、复制、传播、展示、镜像、上载、下载
              内图码上悦读软件及相关服务中的内容）。
            </div>
            <div class="content">
              10.2 您理解并同意，在使用 内图码上悦读软件及相关服务时发布上传的文字、图片、视频、音频等均由您原创或已获合法授权。您通过 内图码上悦读上传、发布的任何内容的知识产权归属您或原始著作权人所有。
            </div>
            <div class="content">
              10.3 您知悉、理解并同意您通过
              内图码上悦读发布上传的公开内容（不包括仅为用户自己可见的内容），授权码上悦读及其关联码上悦读、控制码上悦读可在全球范围内、免费、非独家、可转授权地使用，使用范围包括但不限于在当前或其他网站、应用程序、产品或终端设备等，并授权码上悦读及其关联码上悦读、控制码上悦读对相应内容可进行修改、复制、改编、翻译、汇编或制作衍生产品。
            </div>
            <div class="content">
              10.4
              您确认并同意授权码上悦读以码上悦读自己的名义或委托专业第三方对侵犯您上传发布的享有知识产权的内容进行代维权，维权形式包括但不限于：监测侵权行为、发送维权函、提起诉讼或仲裁、调解、和解等，码上悦读有权对维权事宜做出决策并独立实施。
            </div>
            <div class="content">
              10.5 码上悦读为 内图码上悦读开发、运营提供技术支持，并对 内图码上悦读软件及相关服务的开发和运营等过程中产生的所有数据和信息等享有全部权利。
            </div>
            <div class="content">
              10.6 请您在任何情况下都不要私自使用码上悦读的包括但不限于 内图码上悦读、“内图码上悦读”
              等在内的任何商标、服务标记、商号、域名、网站名称或其他显著品牌特征等（以下统称为“标识”）。未经码上悦读事先书面同意，您不得将本条款前述标识以单独或结合任何方式展示、使用或申请注册商标、进行域名注册等，也不得实施向他人明示或暗示有权展示、使用、或其他有权处理该些标识的行为。由于您违反本协议使用码上悦读上述商标、标识等给码上悦读或他人造成损失的，由您承担全部法律责任。
            </div>
            <div class="content">
              10.7 内图码上悦读可能会对用户提交的某些进行审核以决定是否最终公开发布，最终发布前可能会对部分的标题修改，翻译，或者设置为精选，以确保内容质量，优化全球用户的使用体验。
            </div>
            <div class="content">
              10.8 用户通过下载的全部内容，著作权均归原作者所有。用户对这些内容的分享或转载，受原内容来源的相关条款的限制，不提供任何版权的许可。
            </div>
            <div class="content">
              10.9 用户上传的部分优秀可能会被官方发布到专题模块，或者在官方的社交网络账号进行推荐。
            </div>
            <div class="content">
              10.10 任何用户可以下载其它人发布的作为个人使用，但这些的著作权仍然属于原作者，下载者应当在分享或转载的时候标明原作者，并且承诺不将这些用于任何商业用途。如果任何用户侵犯了原作者的相关权利，不承担相关责任。
            </div>
            <div class="content">
              10.11 有权但无义务对用户提交的内容进行审核，有权根据相关证据结合《侵权责任法》、《信息网络传播权保护条例》等法律法规对违反法规或侵权的信息进行处理。
            </div>
            <div class="content">
              11、隐私政策
            </div>
            <div class="content">
              内图码上悦读非常重视用户信息的保护，在使用 内图码上悦读软件及相关服务前，请您务必仔细阅读本隐私政策，码上悦读可能会收集和使用您的相关信息，您一旦选择使用
              内图码上悦读软件及相关服务，即意味着同意码上悦读按照本隐私政策收集、使用（含商业合作使用）、储存您的相关信息。
            </div>
            <div class="content">
              11.1 个人信息可能收集的范围与方式
            </div>
            <div class="content">
              当您在使用
              内图码上悦读软件及相关服务时，我们将收集、储存和使用下列与您个人信息有关的数据，这些信息可用于您注册、登录、绑定账号、密码找回时接收验证码等，如果您不提供相关信息，可能无法注册成为我们的用户或无法享受我们提供的某些服务，或者无法达到相关服务拟达到的效果。在您使用
              内图码上悦读时，根据中华人民共和国法律，您应通过您的账号提供您的真实身份信息，我们将会根据您的身份属性协助您选择合适的方式完成实名验证。
            </div>
            <div class="content">
              1.您向我们提供的信息
            </div>
            <div class="content">
              1.1当您注册、登录或使用 内图码上悦读软件及服务时填写和/或提供的信息，可能包括姓名、手机号、邮箱、地址等单独或者结合识别用户身份的信息。您可以选择不提供某一或某些信息，但是这样可能使您无法使用
              内图码上悦读的相关特殊服务。
            </div>
            <div class="content">
              1.2 若您以其他方式关联登录 内图码上悦读，我们会向第三方请求您的个人信息，对于我们需要但第三方无法提供的个人信息，我们仍会要求您提供。如果您拒绝提供，将会导致您无法使用码上悦读的某些服务。
            </div>
            <div class="content">
              2.因您使用 内图码上悦读软件及服务获取的信息
            </div>
            <div class="content">
              2.1日志信息，当您使用
              内图码上悦读软件及服务时，我们会自动收集您对我们服务的使用情况，作为有关网络日志保存。例如您的搜索查询内容、IP地址、浏览器的类型、使用的语言、访问服务的日期和时间、cookies、web
              beacon等。
            </div>
            <div class="content">
              2.2设备或应用信息，某些移动设备或应用包含唯一应用程序编号。例如您使用的移动设备、浏览器或您使用的用于接入 内图码上悦读服务的其他程序所提供的配置信息、设备版本号、设备识别码、IP地址等。
            </div>
            <div class="content">
              为了提供更好的服务与改善用户体验， 内图码上悦读软件可能会记录硬件型号、操作系统版本号、国际移动设备身份码（IMEI）、网络设备硬件地址（MAC）等信息数据。
            </div>
            <div class="content">
              2.3位置信息，当您开启设备定位功能并使用
              内图码上悦读基于位置提供的相关服务时，在获得您的同意后，我们会使用各种技术进行定位，以使得您不需要手动输入自身地理坐标就可获得相关服务。这些技术包括IP地址、GPS以及能够提供相关信息的其他传感器（比如可能需要提供附近设备、Wi-Fi接入点和基站的信息）。您可以通过关闭定位功能，停止告知
              内图码上悦读软件关于您的地理位置（大多数移动设备允许您关闭定位服务，具体建议您联系您的移动设备的服务商或生产商）。
            </div>
            <div class="content">
              3.我们从第三方获得的您的信息
            </div>
            <div class="content">
              我们可能从第三方获取您授权共享的账户信息（头像、昵称等），并在您同意本《隐私政策》后将您的第三方账户与您的码上悦读账户绑定，使您可以通过第三方账户直接登录并使用我们的产品与/或服务。我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。
            </div>
            <div class="content">
              4.当您使用码上悦读的搜索服务时，我们会收集您的查询关键字信息、设备信息等，为了提供高效的搜索服务，这些信息有部分会暂存存储在您的本地存储设备之中。在此，您需要注意的是，您的关键词信息无法单独识别您的个人身份，其不属于您的个人信息，因此我们有权以其他的目的对其进行使用；只有当您的搜索关键词信息与您的其他信息互有联系并可以识别您的个人身份时，则在结合使用期间，我们会将您的搜索关键词信息作为您的个人信息，与您的搜索历史记录一同按照本隐私政策对其进行处理与保护。
            </div>
            <div class="content">
              5.在您使用 内图码上悦读提供的身份认证服务时，我们会收集您的姓名、身份证号、职业、有关身份证明等信息， 内图码上悦读对您的这些隐私信息会加以最大程度的保护，如果您不提供这些信息，我们将无法提供相关服务。
            </div>
            <div class="content">
              6.当您参加 内图码上悦读的有关营销活动时，我们会收集您的姓名、通信地址、联系方式、银行账号等信息。这些信息是您收到转账或者礼品的基础，如果您拒绝提供这些信息，我们将无法向您转账或发放礼品。
            </div>
            <div class="content">
              11.2 个人信息的存储
            </div>
            <div class="content">
              1.信息存储的地点
              我们依照法律法规的规定，将境内收集的您的个人信息存储于中华人民共和国境内。
            </div>
            <div class="content">
              2.存储期限
              我们仅在本《隐私政策》所述目的所必需的期间和法律法规要求的时限内保留您的个人信息。
            </div>
            <div class="content">
              11.3对Cookie和同类技术的使用
            </div>
            <div class="content">
              1.当您使用
              内图码上悦读产品或服务时，为使您获得更轻松的访问体验，我们可能会使用各种技术来收集和存储信息，在此过程中可能会向您的设备发送一个或多个Cookie或匿名标识符。这么做是为了了解您的使用习惯，使您省去重复输入注册信息等步骤，或帮助判断您的账户安全。
            </div>
            <div class="content">
              2.当您使用码上悦读产品或服务时，我们可能会利用Cookie和同类技术收取您的信息用于了解您的偏好，进行咨询或数据分析，改善产品服务即用户体验，提高广告效果，及时发现并防范安全风险，为用户和合作伙伴提供更好的服务。
            </div>
            <div class="content">
              3.我们不会将Cookie用于本《隐私政策》所述目的之外的用途，您可以根据自己的偏好留存或删除Cookie。您可清除软件或网页中保存的所有Cookie。
            </div>
            <div class="content">
              11.4个人信息可能的使用方式
            </div>
            <div class="content">
              1.我们会根据本隐私政策的约定并为实现我们的产品与/或服务功能对所收集的个人信息进行使用。
            </div>
            <div class="content">
              2.通过使用收集的信息的数据， 内图码上悦读软件会得以向您提供尽可能的带有个性化的服务并改善现有服务。
            </div>
            <div class="content">
              3.通过使用收集的信息的数据， 内图码上悦读软件向您提供更加相关的广告以替代普遍投放的广告。
            </div>
            <div class="content">
              4.在我们提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性。
            </div>
            <div class="content">
              5.通过使用收集的信息的数据，使我们更加了解您如何接入和使用我们的服务，从而针对性地回应您的个性化需求，例如语言设定、位置设定、个性化的帮助服务和指示等。
            </div>
            <div class="content">
              6.为了确保服务的安全，帮助我们更好地了解我们应用程序的运行情况，我们可能记录相关信息数据，例如，您使用应用程序的频率、崩溃数据、总体使用情况、性能数据等。我们不会将我们存储在分析软件中的信息与您在应用程序中提供的任何个人身份信息相结合。
            </div>
            <div class="content">
              7.为了让您有更好的体验、改善我们的服务或您同意的其他用途，在符合相关法律法规的前提下，我们可能将通过某一项服务所收集的信息数据，以汇集信息数据或者个性化的方式，用于我们的其他服务。例如，在您使用我们的一项服务时所收集的信息，可能在另一服务中用于向您提供特定内容，或向您展示与您相关的、非普遍推送的信息。如果我们在相关服务中提供了相应选项，您也可以授权我们将该服务所提供和储存的信息用于我们的其他服务。
            </div>
            <div class="content">
              8.在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别主体。请您了解并同意，在此情况下我们有权使用已经去标识化的信息；并在不透露您个人信息的前提下，我们有权对用户数据库进行分析并予以商业化的利用。
            </div>
            <div class="content">
              9.请您注意，您在使用我们的产品与/或服务时所提供的所有个人信息，除非您删除或通过系统设置拒绝我们收集，否则将在您使用我们的产品与/或服务期间持续授权我们使用。在您注销账号后，我们将停止为您提供产品或服务，根据您的要求删除您的个人信息，或做匿名化处理，但法律法规另有规定的除外。
            </div>
            <div class="content">
              10.我们会对我们的产品与/或服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示我们的产品与/或服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。
            </div>
            <div class="content">
              11.5个人信息可能的共享方式
              除以下情形外，未经您的同意，我们以及我们的关联码上悦读、控制码上悦读不会与任何第三方共享您的个人信息：
            </div>
            <div class="content">
              1.随着我们业务的持续发展，我们以及我们的关联码上悦读有可能进行合并、收购、资产转让或类似的交易，您的个人信息有可能作为此类交易的一部分而被转移，我们将在转移前通知您，我们将按照法律法规及不低于本隐私政策所要求的标准继续保护或要求新的控制者继续保护你的个人信息。
            </div>
            <div class="content">
              2.未经您本人允许，我们不会向任何第三方共享、转让、公开披露您的个人信息，下列情形除外：
            </div>
            <div class="content">
              （1） 事先获得您的明确授权同意；
            </div>
            <div class="content">
              （2） 您自行向第三方共享、转让、公开的；
            </div>
            <div class="content">
              （3）与国家安全、国防安全、公共安全、公共卫生、公共利益直接相关的；
            </div>
            <div class="content">
              （4）
              根据适用的法律法规、法规程序的要求、强制性的行政司法要求所必须的情况下进行披露或提供，或与犯罪侦查、起诉、审判和判决执行等直接相关的；我们会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。我们将对所有的请求都进行了审慎的审查，以确保其具备合法依据，且仅限于行政、司法部门因特定调查目的有合法权利获取的数据；
            </div>
            <div class="content">
              （5） 在法律法规允许的范围内，为维护
              内图码上悦读其他用户、码上悦读及其关联码上悦读、控制码上悦读的生命、财产等合法权益或维权产品或服务的安全稳定运行所必需的，例如查找、预防、处理欺诈等违法活动和减少信用风险等；不过这并不包括违反本隐私政策中所做的承诺而为获利目的对外公开或提供个人信息；
            </div>
            <div class="content">
              （6） 码上悦读为维护合法权益而向用户提起诉讼或仲裁；
            </div>
            <div class="content">
              （7） 在涉及合并、分立、收购、资产转让或类似的交易时，如涉及到个人信息转让，码上悦读会要求新的持有您的个人信息的码上悦读、组织继续受本隐私政策的约束，否则，码上悦读有权要求该码上悦读、组织重新取得您的授权同意；
            </div>
            <div class="content">
              （8）从合法公开披露的信息中个人信息的，如合法的新闻报道、政府信息公开等渠道；
            </div>
            <div class="content">
              （9）为学术研究目的，或为学研究机构，出于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
            </div>
            <div class="content">
              （10）法律法规规定的其他情形。
            </div>
            <div class="content">
              11.6个人信息安全保护
            </div>
            <div class="content">
              1.我们非常重视信息安全，我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的个人信息，防止您的信息被不当使用或被未经授权的访问、使用或泄漏。
            </div>
            <div class="content">
              2.我们会使用加密技术、匿名化处理等手段保护您的个人信息；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击。
            </div>
            <div class="content">
              3.我们建立专门的安全部门、安全管理制度、数据安全流程保障您的个人信息安全。我们采取严格的数据使用和访问制度，对数据和技术进行安全审计。制定应急处理预案，对个人信息泄露等安全事件，我们会立即启动应急预案，阻止安全事件扩大。
            </div>
            <div class="content">
              4.尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。因此，您应采取积极措施保护个人信息的安全，如：使用复杂密码、定期修改密码、不将自己的账号密码等个人信息透露给他人。
            </div>
            <div class="content">
              5.一旦发生用户信息安全事件（泄露、丢失等）后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已经采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以推送通知、邮件、信函、短信等形式告知您，难以逐一告知用户信息主体时，我们会采取合理、有效的方式发布公告。
            </div>
            <div class="content">
              6.同时，我们还将按照监管部门要求，上报用户信息安全事件的处置情况。
              请您知悉： 内图码上悦读提供的个人信息保护措施仅适用于 内图码上悦读平台，一旦您离开码上悦读，浏览或使用其他网站、服务及内容资源，我们即没有能力及义务保护您在
              内图码上悦读之外的网站提交的任何个人信息，无论您登录或浏览上述网站是否基于 内图码上悦读的链接或引导。
            </div>
            <div class="content">
              11.7个人信息的管理
              我们非常重视您对个人信息的关注，并尽全力保护您对于您个人信息的访问、更正、删除以及撤回同意的权利，以使您拥有充分的能力保障您的隐私和安全。
            </div>
            <div class="content">
              1.当您完成 内图码上悦读的账号注册并进行合理和必要的身份验证后，您可以查阅、修改、删除您的提交给
              内图码上悦读的个人信息。一般情况下，你可以随时浏览、修改、删除自己提交的信息，但出于安全性和身份识别（如号码申诉服务）的考虑，您可能无法修改注册时提交的某些初始注册信息。
            </div>
            <div class="content">
              2.您有权自主更新或更正您的的个人信息，在您进行信息更新更正之前，我们会首先验证您的身份，其次才能进行信息的更正与更新。
            </div>
            <div class="content">
              3.您可以在我们的产品中申请注销账户。在您注销账号前，我们将验证您的个人身份、安全状态、设备信息等。您注销账号的行为是不可逆的行为，一旦您注销账号，
              内图码上悦读将停止为您提供产品与/或服务，并依照您的要求，除法律法规另有规定外，删除有关您账号的一切信息。
            </div>
            <div class="content">
              4.您的信息有以下情形之一时，按照法律法规要求，我们可能无法响应您的请求：
            </div>
            <div class="content">
              4.1与国家安全、国防安全有关的；
            </div>
            <div class="content">
              4.2与公共安全、公共卫生、重大公共利益等有关的；
            </div>
            <div class="content">
              4.3与犯罪侦查、起诉、审判和执行判决等有关的；
            </div>
            <div class="content">
              4.4有充分证据表明您存在主观恶意或滥用权利的；
            </div>
            <div class="content">
              4.5响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；
            </div>
            <div class="content">
              4.6涉及商业秘密的；
            </div>
            <div class="content">
              4.7其他法律法规规定的情形。
            </div>
            <div class="content">
              11.8本隐私政策不适用情况
            </div>
            <div class="content">
              1.本隐私政策不适用于以下情况：
            </div>
            <div class="content">
              1.1通过 内图码上悦读软件及服务而接入的第三方服务（包括任何第三方应用及网站）收集的信息；
            </div>
            <div class="content">
              1.1通过 内图码上悦读软件及服务而接入的第三方服务（包括任何第三方应用及网站）收集的信息；
            </div>
            <div class="content">
              2.我们的服务可能包括或链接至第三方提供的信息或其他服务（包括网站）。该等第三方服务可能由相关的第三方运营。您使用该等第三方服务（包括您向该等第三方提供的任何个人信息），须受该第三方的服务条款及隐私条款（而非本隐私政策）约束，您需要仔细阅读其条款。请您妥善保护自己的个人信息，仅在必要的情况下向他人提供。本隐私条款仅适用于我们所收集、保存、使用、共享、披露信息，并不适用于任何第三方提供的服务或第三方的信息使用规则，我们对任何第三方使用由您提供的信息不承担任何责任。
            </div>
            <div class="content">
              12、免责声明
            </div>
            <div class="content">
              12.1 您理解并同意， 内图码上悦读软件及相关服务可能会受多种因素的影响或干扰，码上悦读不保证(包括但不限于)：
            </div>
            <div class="content">
              12.1.1码上悦读完全适合用户的使用要求；
            </div>
            <div class="content">
              12.1.2 码上悦读不受干扰，及时、安全、可靠或不出现错误；用户经由码上悦读取得的任何软件、服务或其他材料符合用户的期望；
            </div>
            <div class="content">
              12.1.3软件中任何错误都将能得到更正。
            </div>
            <div class="content">
              12.2
              对于涉嫌借款或其他涉财产的网络信息、账户密码、广告或推广等信息的，用户请谨慎对待并自行进行判断，基于前述原因您因此遭受的利润、商业信誉、资料损失或其他有形或无形损失，码上悦读不承担任何直接、间接、附带、特别、衍生性或惩罚性的赔偿责任。
            </div>
            <div class="content">
              12.3 用户理解并同意，在使用
              内图码上悦读软件及相关服务过程中，可能遇到不可抗力等因素（不可抗力是指不能预见、不能克服并不能避免的客观事件），包括但不限于政府行为、自然灾害、网络原因、黑客攻击、战争或任何其它类似事件。出现不可抗力情况时，码上悦读将努力在第一时间及时修复，但因不可抗力给用户造成了损失，用户同意码上悦读不承担责任。
            </div>
            <div class="content">
              12.4 码上悦读依据本协议约定获得处理违法违规内容的权利，该权利不构成码上悦读的义务或承诺，码上悦读不能保证及时发现违法行为或进行相应处理。
            </div>
            <div class="content">
              12.5 用户阅读、理解并同意：关于本协议服务，码上悦读不提供任何种类的明示或暗示担保或条件，包括但不限于商业适售性、特定用途适用性等。您对本协议软件及相关服务的使用行为必须自行承担相应风险。
            </div>
            <div class="content">
              12.6
              用户阅读、理解并同意，本协议是在保障遵守国家法律法规、维护公序良俗，保护他人合法权益，码上悦读在能力范围内尽最大的努力按照相关法律法规进行判断，但并不保证码上悦读判断完全与司法机关、行政机关的判断一致，如因此产生的后果用户已经理解并同意自行承担。
            </div>
            <div class="content">
              13、未成年人使用条款
            </div>
            <div class="content">
              13.1 若用户是未满 18 周岁的未成年人，应在监护人监护、指导并获得监护人同意情况下阅读本协议和使用 内图码上悦读软件及相关服务。
            </div>
            <div class="content">
              13.2 码上悦读重视对未成年人个人信息的保护，未成年用户在填写个人信息时，请加强个人保护意识并谨慎对待，请在监护人指导时正确使用 内图码上悦读软件及相关服务。
            </div>
            <div class="content">
              13.3 未成年用户理解如因您违反法律法规、本协议内容，则您及您的监护人应依照法律规定承担因此而导致的一切后果。
            </div>
            <div class="content">
              13.4 未成年人用户特别提示：
            </div>
            <div class="content">
              13.4.1 青少年使用本软件及相关服务应该在其监护人的监督指导下，在合理范围内正确学习使用网络，避免沉迷虚拟的网络空间，养成良好上网习惯。
            </div>
            <div class="content">
              13.4.2 青少年用户必须遵守《全国青少年网络文明公约》：
            </div>
            <div class="content">
              （1）要善于网上学习，不浏览不良信息；
            </div>
            <div class="content">
              （2）要诚实友好交流，不侮辱欺诈他人；
            </div>
            <div class="content">
              （3）要增强自护意识，不随意约会网友；
            </div>
            <div class="content">
              （4）要维护网络安全，不破坏网络秩序；
            </div>
            <div class="content">
              （5）要有益身心健康，不沉溺虚拟时空。
            </div>
            <div class="content">
              13.5
              为更好的保护未成年人隐私权益，码上悦读提醒用户慎重发布包含未成年人素材的内容，一经发布，即视为用户同意本软件及相关服务展示未成年人的信息、肖像、声音等，且允许码上悦读依据本协议使用、处理该等与未成年人相关的内容。
            </div>
            <div class="content">
              14、其他
            </div>
            <div class="content">
              14.1
              本协议的成立、生效、履行、解释及争议的解决均应适用中华人民共和国大陆地区法律。倘本协议之任何规定因与中华人民共和国大陆地区的法律抵触而无效，则这些条款将尽可能接近本协议原条文意旨重新解析，且本协议其它规定仍应具有完整的效力及效果。
            </div>
            <div class="content">
              14.3
              码上悦读有权依据国家政策、技术条件、产品功能等变化需要而进行修改本协议，码上悦读会将修改后的协议予以发布。前述内容一经正式发布，并以适当的方式送达用户（网站公布、系统通知等），即为本协议不可分割的组成部分，您应同样遵守。您对修改后的协议有异议的，请立即停止登录、使用
              内图码上悦读软件及相关服务，若您登录或继续使用 内图码上悦读软件及相关服务，视为认可修改后的协议。
            </div>
            <div class="content">
              14.4 本协议中的标题仅为方便及阅读而设，并不影响本协议中任何规定的含义或解释。
            </div>
            <div class="content">
              14.5 您和码上悦读均是独立的主体，在任何情况下本协议不构成码上悦读对用户的任何形式的明示或暗示担保或条件，双方之间亦不构成代理、合伙、合营或雇佣关系。
            </div>
            <div class="content">
              14.6 本协议的版权为码上悦读所有，码上悦读保留一切解释和修改的权利。
            </div>
          </div>
        </div>
      </scroll>
    </div>
  </transition>
</template>

<script>
import Scroll from '@/components/scroll/scroll'

export default {
  name: 'PrivacyAgreement',
  components: {
    Scroll
  },
  data () {
    return {
      data: []
    }
  }
}
</script>

<style scoped lang="stylus">
.slide-enter-active, .slide-leave-active
  transition: all 0.3s

.slide-enter, .slide-leave-to
  transform: translate3d(100%, 0, 0)

.desc
  position absolute
  z-index 100
  top 0
  left 0
  right 0
  bottom 0
  background rgba(245, 245, 244, 1)

  .scroll
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    overflow hidden
    background rgba(255, 255, 255, 1)

    .title
      width 750px
      font-size 32px
      line-height 40px
      text-align center

    .content-block
      width 690px
      padding 30px

      .content
        width 690px
        text-indent 48px
        line-height 40px
        font-size 24px
</style>
